import * as React from "react";
import { navigate } from "gatsby";

export default () => {
  React.useEffect(() => {
    navigate("/promo/ntu");
  }, []);

  return null;
};
